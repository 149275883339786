<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.184 32.3776H7.70833C6.89058 32.3776 6.10632 32.0528 5.52809 31.4745C4.94985 30.8963 4.625 30.112 4.625 29.2943V10.7943C4.625 9.97652 4.94985 9.19226 5.52809 8.61402C6.10632 8.03579 6.89058 7.71094 7.70833 7.71094H26.2083C27.0261 7.71094 27.8103 8.03579 28.3886 8.61402C28.9668 9.19226 29.2917 9.97652 29.2917 10.7943V16.9609"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.7526 33.9193C29.3881 33.9193 30.9566 33.2696 32.1131 32.1131C33.2696 30.9566 33.9193 29.3881 33.9193 27.7526C33.9193 26.1171 33.2696 24.5486 32.1131 23.3921C30.9566 22.2356 29.3881 21.5859 27.7526 21.5859C26.1171 21.5859 24.5486 22.2356 23.3921 23.3921C22.2356 24.5486 21.5859 26.1171 21.5859 27.7526C21.5859 29.3881 22.2356 30.9566 23.3921 32.1131C24.5486 33.2696 26.1171 33.9193 27.7526 33.9193Z"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M23.125 4.625V10.7917"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.7891 4.625V10.7917"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.625 16.9609H29.2917"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.75 25.4258V27.7444L29.2917 29.2861"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#0AC0CB",
    },
  },
};
</script>