<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.6824 7.50948C23.0433 6.9207 20.4563 5.04333 14.6886 5.01823C14.6886 5.01823 7.88911 4.61059 4.57741 7.6505C2.73534 9.49246 2.08611 12.1952 2.01563 15.5421C1.94515 18.8891 1.85959 25.16 7.90418 26.8612H7.90916L7.90418 29.4582C7.90418 29.4582 7.86395 30.5101 8.55845 30.7215C9.39396 30.9832 9.88716 30.183 10.6874 29.3223C11.1253 28.8493 11.7292 28.1547 12.1872 27.6262C16.3243 27.9735 19.5001 27.1783 19.8625 27.0626C20.698 26.7908 25.4239 26.1868 26.1889 19.9158C26.984 13.4434 25.8064 9.3566 23.6824 7.50948Z"
      :fill="iconColor"
    />
    <path
      d="M24.3816 19.4437C23.7324 24.6778 19.9023 25.0101 19.1977 25.2366C18.8957 25.3322 16.1024 26.0268 12.5945 25.8003C12.5945 25.8003 9.97732 28.9559 9.16198 29.7764C8.89524 30.043 8.60334 30.0179 8.60838 29.4894C8.60838 29.1423 8.6285 25.1762 8.6285 25.1762C8.62346 25.1762 8.62346 25.1762 8.6285 25.1762C3.50493 23.7569 3.80691 18.4169 3.86226 15.6237C3.91761 12.8304 4.44611 10.5404 6.00632 9.00038C8.80969 6.4587 14.5825 6.83621 14.5825 6.83621C19.4594 6.85632 21.7947 8.32588 22.3383 8.81913C24.135 10.3592 25.051 14.0434 24.3816 19.4437Z"
      :fill="innerColor"
    />
    <path
      d="M17.3868 15.3767C17.4069 15.8095 16.7577 15.8397 16.7375 15.4068C16.6821 14.2996 16.1637 13.7611 15.0968 13.7007C14.6639 13.6755 14.7042 13.0263 15.132 13.0515C16.5362 13.127 17.3164 13.9322 17.3868 15.3767Z"
      :fill="iconColor"
    />
    <path
      d="M18.4071 15.9461C18.4573 13.8121 17.1237 12.1412 14.5921 11.955C14.1642 11.9248 14.2096 11.2755 14.6374 11.3057C17.5566 11.5172 19.1117 13.5253 19.0563 15.9612C19.0513 16.394 18.397 16.3739 18.4071 15.9461Z"
      :fill="iconColor"
    />
    <path
      d="M20.7746 16.6152C20.7796 17.0481 20.1253 17.053 20.1253 16.6202C20.0951 12.5184 17.3623 10.2838 14.0455 10.2586C13.6177 10.2536 13.6177 9.60938 14.0455 9.60938C17.7548 9.63453 20.7393 12.1963 20.7746 16.6152Z"
      :fill="iconColor"
    />
    <path
      d="M20.2045 21.5563V21.5664C19.6609 22.5227 18.6443 23.5796 17.5974 23.2424L17.5873 23.2272C16.5254 22.9304 14.0241 21.6419 12.4437 20.3836C11.6284 19.7394 10.8834 18.9795 10.3097 18.2497C9.79132 17.6004 9.26792 16.8303 8.7596 15.9043C7.6876 13.9666 7.45106 13.1009 7.45106 13.1009C7.11384 12.0541 8.16573 11.0374 9.12696 10.4939H9.1371C9.6001 10.2522 10.043 10.3328 10.3399 10.6901C10.3399 10.6901 10.9641 11.4349 11.2307 11.8024C11.4824 12.1446 11.8196 12.6932 11.9958 13.0002C12.3028 13.5488 12.1115 14.1074 11.8095 14.3389L11.2056 14.8221C10.8986 15.0687 10.9388 15.5267 10.9388 15.5267C10.9388 15.5267 11.8347 18.9139 15.1816 19.7695C15.1816 19.7695 15.6397 19.8098 15.8862 19.5028L16.3694 18.8988C16.6009 18.5969 17.1596 18.4056 17.7082 18.7127C18.4481 19.1303 19.3892 19.7796 20.0133 20.3685C20.3655 20.6554 20.4461 21.0933 20.2045 21.5563Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#354E57",
    },
    innerColor: {
      type: String,
      default: "#ACE5E1",
    },
  },
};
</script>