<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.8454 11.5275C26.6877 2.48013 14.5797 -0.943611 7.31082 3.18106C-3.61667 9.35864 0.986498 22.3837 12.2423 23.8772L12.3931 23.9071C12.7477 23.9772 12.8431 24.0738 12.8678 24.1118C12.9133 24.1829 12.8895 24.3226 12.8713 24.3995C12.7167 25.0494 12.5259 25.6761 12.6506 26.4028C13.0183 28.3455 15.0602 27.3888 16.0923 26.7128C17.3982 25.9458 18.6286 25.0807 19.5546 24.4128C23.933 21.3612 28.5533 17.5674 27.8454 11.5275Z"
      :fill="iconColor"
    />
    <path
      d="M9.43613 15.6174H7.12164C6.7719 15.6174 6.48828 15.3338 6.48828 14.9841V10.1263C6.50916 9.29283 7.73325 9.28964 7.75471 10.1263V14.3507H9.43555C10.2699 14.371 10.2719 15.5966 9.43613 15.6174Z"
      fill="#F1F1F1"
    />
    <path
      d="M11.8507 14.9685C11.8301 15.8022 10.6054 15.8048 10.584 14.9685C10.584 13.9854 10.584 11.0468 10.584 10.1107C10.6046 9.27692 11.8292 9.27431 11.8507 10.1107V14.9685Z"
      fill="#F1F1F1"
    />
    <path
      d="M17.5735 14.9685C17.5917 15.5627 16.7652 15.8358 16.4277 15.3405L14.1474 12.1998V14.9687C14.1274 15.8022 12.9019 15.8051 12.881 14.9687V10.2499C12.8694 9.64986 13.6831 9.38364 14.0268 9.8778L16.3073 13.0185V10.1107C16.3273 9.27692 17.5529 9.27431 17.5738 10.1107C17.5735 10.1107 17.5735 14.9685 17.5735 14.9685Z"
      fill="#F1F1F1"
    />
    <path
      d="M22.1877 15.4995H19.0534C18.7037 15.4995 18.4201 15.2159 18.4201 14.8662C18.4209 13.6554 18.4195 11.2188 18.4201 10.0084C18.4201 9.65862 18.7037 9.375 19.0534 9.375H22.0906C22.9255 9.39675 22.9264 10.62 22.0906 10.6414H19.6868V11.8038H21.6373C22.4719 11.8246 22.4728 13.0493 21.6373 13.0702H19.6868V14.2325H22.1877C23.0215 14.2528 23.0238 15.4786 22.1877 15.4995Z"
      fill="#F1F1F1"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#354E57",
    },
  },
};
</script>