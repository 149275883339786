<template>
  <li
    class="w-max py-3 px-3 my-5 font-proximaMedium text-left cursor-pointer rounded-full border-transparent flex items-center relative"
    :class="{ 'text-white bg-white': hover, 'bg-primary': !hover }"
  >
    <span
      v-if="hover"
      class="w-max bg-primary py-3 pl-6 pr-16 rounded-full text-md text-white absolute right-0 transition ease-in-out"
      style="z-index: -1"
      @click="handleParentsFunc"
      >{{ title }}</span
    >
    <component
      class="z-20"
      :is="component"
      width="22"
      height="22"
      :iconColor="hoverComp"
      @click.native="hover = !hover"
    />
  </li>
</template>

<script>
import OpenEye from "../../icons/OpenEye.vue";
import CareroutineIcon from "../../icons/CareroutineIcon.vue";
import DiaryIcon from "../../icons/DiaryIcon.vue";
import MedicalhistIcon from "../../icons/MedicalhistIcon.vue";
import SymptomIcon from "../../icons/SymptomIcon.vue";
import TrainingIcon from "../../icons/TrainingIcon.vue";
import NewentryIcon from "../../icons/NewentryIcon.vue";
import CaresheetsIcon from "../../icons/CaresheetsIcon.vue";
import TreatmentIcon from "../../icons/TreatmentIcon.vue";
import ShareIcon from "../../icons/ShareIcon.vue";
import RoutineIcon from "../../icons/RoutineIcon.vue";
import AppointmentIcon from "../../icons/AppointmentIcon.vue";
import EditIcon from "../../icons/EditIcon.vue";
import TransferpetIcon from "../../icons/TransferpetIcon.vue";
import ReclaimpetIcon from "../../icons/ReclaimpetIcon.vue";
import SyringeIcon from "../../icons/SyringeIcon.vue";
import AppleIcon from "../../icons/AppleIcon.vue";
import QuestionmarkIcon from "../../icons/QuestionmarkIcon.vue";
import PhotoGalleryIcon from "../../icons/PhotoGalleryIcon.vue"
import MicIcon from "../../icons/MicIcon.vue";
import UserIcon from "../../icons/UserIcon.vue";
import ContactIcon from "../../icons/ContactIcon.vue";
import EmergencyIcon from "../../icons/EmergencyIcon.vue";
import SymptomManagerIcon from "../../icons/SymptomManagerIcon.vue";
import MedicationIcon from "../../icons/MedicationIcon.vue";
import VisualizedHealthIcon from "../../icons/VisualizedHealthIcon.vue";
import ParasiteIcon from "../../icons/ParasiteIcon.vue";
import ContactBookIcon from '../../icons/ContactBookIcon.vue';

export default {
  props: {
    title: {
      type: String
    },
    component: {
      type: String
    },
    clickFunction: {
      type: Function,
      required: false
    }
  },
  components: {
    OpenEye,
    CareroutineIcon,
    DiaryIcon,
    MedicalhistIcon,
    SymptomIcon,
    TrainingIcon,
    NewentryIcon,
    CaresheetsIcon,
    TreatmentIcon,
    ShareIcon,
    RoutineIcon,
    AppointmentIcon,
    EditIcon,
    TransferpetIcon,
    ReclaimpetIcon,
    SyringeIcon,
    AppleIcon,
    QuestionmarkIcon,
    PhotoGalleryIcon,
    MicIcon,
    UserIcon,
    EmergencyIcon,
    ContactIcon,
    SymptomManagerIcon,
    MedicationIcon,
    VisualizedHealthIcon,
    ParasiteIcon,
    ContactBookIcon
  },
  data() {
    return {
      hover: false,
    };
  },
  // created() {
  //   this.$parent.$on("clicked", this.handleClicked);
  // },
  methods: {
    // toggle() {
    //   this.hover = !this.hover;
    // },
    handleClicked() {
      this.hover = !this.hover;
    },
    handleParentsFunc() {
      this.clickFunction();
      this.hover = false;
    }
  },
  computed: {
    hoverComp() {
      if (this.hover) {
        return "#0AC0CB";
      } else {
        return "#fff";
      }
    },
  },
};
</script>