<template>
  <div>
    <ul class="md:block fixed hidden top-36 right-5">
      <!-- <NavEach
        @click.native="serviceProviderModals = !serviceProviderModals"
        :title="$t('pet_dropdown_menu_share_diary')"
        component="DiaryIcon"
        :data="data"
      /> -->
     <!--  <NavEach
        @click.native="vetModals = !vetModals"
        :title="$t('pet_dropdown_menu_share_medical_history')"
        component="MedicalhistIcon"
      /> -->
    </ul>
    <div class="md:hidden">
      <span
        class="bg-white h-11 w-11 m-auto text-center pt-2 text-primary rounded-full text-xl absolute top-20 right-4 cursor-pointer"
        @click="mobileMenu = !mobileMenu"
        v-if="mobileMenu"
        >&#9650;</span
      >
      <span
        class="bg-white h-11 w-11 m-auto text-center pt-2 text-orange rounded-full text-xl absolute top-20 right-4 cursor-pointer"
        @click="mobileMenu = !mobileMenu"
        v-else
        >&#9660;</span
      >
      <transition name="fade">
        <ul v-if="mobileMenu" class="fixed top-32 right-3" :key="1">
          <!--  <NavEachMobile
            @click.native="serviceProviderModals = !serviceProviderModals"
            :title="$t('pet_dropdown_menu_share_diary')"
            component="DiaryIcon"
            :data="data"
          /> -->
          <!-- <NavEachMobile
            @click.native="vetModals = !vetModals"
            :title="$t('pet_dropdown_menu_share_medical_history')"
            component="MedicalhistIcon"
          /> -->
        </ul>
      </transition>
    </div>

    <!-- service provider modals -->
    <ModalsConfirm
      :show="serviceProviderModals"
      @close="serviceProviderModals = false"
    >
      <div
        slot="actionQuestion"
        class="w-max p-4 m-auto bg-primary-20 rounded-full"
      >
        <DiaryIcon width="64" height="64" />
      </div>
      <h1 slot="actionQuestion" class="mt-4 text-2xl">
        {{ $t("nav_sharing_setting_service_provider_modals_title") }}
      </h1>
      <div
        slot="actionText"
        class="flex flex-col items-center justify-center text-sm font-proximaLight"
      >
        <span class="my-2">{{
          $t("nav_sharing_setting_service_provider_modals_text")
        }}</span>
        <input
          type="text"
          v-model="spEmailAddress"
          class="w-10/12 md:w-7/12 border-2 py-3 px-4 border-primary rounded-md"
          :placeholder="$t('ownership_section_enter_email_phone_number')"
        />
      </div>
      <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        :title="$t('global_cancel')"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="serviceProviderModals = false"
      />
      <SubmitButton
        slot="rightBtn"
        class="md:ml-1"
        :title="$t('global_confirm')"
        background="bg-success"
        paddingX="px-8"
        width="w-full"
        @click.native="extServiceProvider"
      />
    </ModalsConfirm>
    <ModalsConfirm
      :show="extServiceProviderModals"
      @close="extServiceProviderModals = false"
    >
      <h1 slot="actionQuestion" class="mt-4 text-2xl">
        {{ $t("nav_sharing_setting_ext_service_provider_modals_title") }}
      </h1>
      <div
        slot="actionText"
        class="flex flex-col items-center justify-center text-sm font-proximaLight"
      >
        <span class="my-2">{{
          $t("nav_sharing_setting_ext_service_provider_modals_text")
        }}</span>
      </div>
      <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        :title="$t('global_decline')"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="extServiceProviderModals = false"
      />
      <SubmitButton
        slot="rightBtn"
        class="md:ml-1"
        :title="$t('global_accept')"
        background="bg-success"
        paddingX="px-8"
        width="w-full"
        @click.native="submitExtProvider"
      />
    </ModalsConfirm>
    <!-- service provider modals end -->

    <!-- vet modals -->
    <ModalsConfirm :show="vetModals" @close="vetModals = false">
      <div
        slot="actionQuestion"
        class="w-max p-4 m-auto bg-primary-20 rounded-full"
      >
        <MedicalhistIcon width="64" height="64" />
      </div>
      <h1 slot="actionQuestion" class="mt-4 text-2xl">
        {{ $t("nav_sharing_setting_vet_modals_title") }}
      </h1>
      <div
        slot="actionText"
        class="flex flex-col items-center justify-center text-sm font-proximaLight"
      >
        <span class="my-2">{{
          $t("nav_sharing_setting_vet_modals_text")
        }}</span>
        <input
          type="text"
          v-model="vetEmailAddress"
          class="w-10/12 md:w-7/12 border-2 py-3 px-4 border-primary rounded-md"
          :placeholder="$t('ownership_section_enter_email_phone_number')"
        />
      </div>
      <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        :title="$t('global_cancel')"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="vetModals = false"
      />
      <SubmitButton
        slot="rightBtn"
        class="md:ml-1"
        :title="$t('global_confirm')"
        background="bg-success"
        paddingX="px-8"
        width="w-full"
        @click.native="extVet"
      />
    </ModalsConfirm>
    <ModalsConfirm :show="extVetModals" @close="extVetModals = false">
      <h1 slot="actionQuestion" class="mt-4 text-2xl">
        {{ $t("nav_sharing_setting_ext_vet_modals_title") }}
      </h1>
      <div
        slot="actionText"
        class="flex flex-col items-center justify-center text-sm font-proximaLight"
      >
        <span class="my-2">{{
          $t("nav_sharing_setting_ext_vet_modals_text")
        }}</span>
      </div>
      <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        :title="$t('global_decline')"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="extVetModals = false"
      />
      <SubmitButton
        slot="rightBtn"
        class="md:ml-1"
        :title="$t('global_accept')"
        background="bg-success"
        paddingX="px-8"
        width="w-full"
        @click.native="submitExtVet"
      />
    </ModalsConfirm>
    <!-- vet modals end -->
  </div>
</template>

<script>
import NavEach from "./NavEach.vue";
import NavEachMobile from "./NavEachMobile.vue";
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import DiaryIcon from "../../icons/DiaryIcon.vue";
import MedicalhistIcon from "../../icons/MedicalhistIcon.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import {
  shareCareBlog,
  shareMedicalHistory,
} from "../../../services/sharingService";

export default {
  components: {
    NavEach,
    NavEachMobile,
    ModalsConfirm,
    DiaryIcon,
    MedicalhistIcon,
    SubmitButton,
    BorderedButton,
  },
  data() {
    return {
      screen: false,
      mobileMenu: false,
      serviceProviderModals: false,
      extServiceProviderModals: false,
      vetModals: false,
      extVetModals: false,
      spEmailAddress: "",
      vetEmailAddress: "",
      data: {},
    };
  },
  created() {
    var retrievedObject = localStorage.getItem("pet");
    this.data = JSON.parse(retrievedObject);

    window.mobileCheck = function () {
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return (this.screen = true);
    };
  },
  methods: {
    async extServiceProvider() {
      try {
        const data = {
          spEmailAddress: this.spEmailAddress,
          granted_by: localStorage.getItem("email"),
          petID: this.data.petID,
        };
        await shareCareBlog(data);
      } catch (error) {
        console.log(error);
      }

      this.serviceProviderModals = false;
      this.extServiceProviderModals = true;
    },
    async extVet() {
      try {
        /*  const data = {
          spEmailAddress: this.vetEmailAddress,
          granted_by: localStorage.getItem("email"),
          petID: this.data.petID,
        }; */
        await shareMedicalHistory(this.spEmailAddress);
      } catch (error) {
        console.log(error);
      }

      this.vetModals = false;
      this.extVetModals = true;
    },
    async submitExtVet() {
      try {
        /*  const data = {
          spEmailAddress: this.vetEmailAddress,
          granted_by: localStorage.getItem("email"),
          petID: this.data.petID,
        }; */
        await shareMedicalHistory(this.vetEmailAddress);
      } catch (error) {
        console.log(error);
      }

      this.vetModals = false;
      this.extVetModals = true;
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>