<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.875 5.625H28.125L33.75 18.75L22.5 22.5L11.25 18.75L16.875 5.625Z"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.5 22.5L16.875 5.625"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28.125 20.625L22.5 5.625"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.5 36.5625L16.875 39.375L17.8125 32.8125L14.0625 29.0625L19.6875 28.125L22.5 22.5L25.3125 28.125L30.9375 29.0625L27.1875 32.8125L28.125 39.375L22.5 36.5625Z"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#059AA3",
    },
  },
};
</script>