<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.666 21.3346V16.0013C10.666 14.5868 11.2279 13.2303 12.2281 12.2301C13.2283 11.2299 14.5849 10.668 15.9993 10.668C17.4138 10.668 18.7704 11.2299 19.7706 12.2301C20.7708 13.2303 21.3327 14.5868 21.3327 16.0013V21.3346"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.5333 7.46667L23.6 8.4L24.5333 7.46667ZM4 16H5.33333H4ZM16 4V5.33333V4ZM26.6667 16H28H26.6667ZM7.46667 7.46667L8.4 8.4L7.46667 7.46667Z"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.6667 21.332H9.33333C8.97971 21.332 8.64057 21.4725 8.39052 21.7226C8.14048 21.9726 8 22.3117 8 22.6654V25.332C8 25.6857 8.14048 26.0248 8.39052 26.2748C8.64057 26.5249 8.97971 26.6654 9.33333 26.6654H22.6667C23.0203 26.6654 23.3594 26.5249 23.6095 26.2748C23.8595 26.0248 24 25.6857 24 25.332V22.6654C24 22.3117 23.8595 21.9726 23.6095 21.7226C23.3594 21.4725 23.0203 21.332 22.6667 21.332Z"
      :stroke="iconColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#354E57",
    },
  },
};
</script>