<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5859 16.7793V15.7441H16.4336V11.6035H17.5664V15.7441H21.4141V16.7793H17.5664V21.0371H16.4336V16.7793H12.5859Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#0AC0CB",
    },
  },
};
</script>