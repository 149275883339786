<template>
  <div>
    <NavNewpet v-if="this.page == 'newpet'" :data="data" />
    <NavSharingsetting v-if="this.page == 'sharingsetting'" :data="data" />
    <NavAccountsetting v-if="this.page == 'account'" :data="data" />
    <NavPetoverview v-if="this.page == 'petoverview'" />
    <NavTrainingplan v-if="this.page == 'trainingplan'" />
    <NavCareBlog v-if="this.page == 'careblog'" />
    <NavMenuBreederProfile v-if="this.page == 'breederprofile'" />
    <NavPetDiary v-if="this.page == 'PetownerDiaryView'" />
    <NavMedicalDashboard v-if="this.page == 'medicalhistorydashboard'" />
    <NavSpPassiveDashboard v-if="this.page == 'sppassivedashboard'" />
    <NavContacts v-if="this.page == 'contacts'" />
    <NavHealthLogOverview v-if="this.page == 'healthlogoverview'" />
    <NavMedication v-if="this.page == 'medication'" />
  </div>
</template>

<script>
import NavNewpet from "./NavNewpet.vue";
import NavSharingsetting from "./NavSharingsetting.vue";
import NavAccountsetting from "./NavAccountsetting.vue";
import NavPetoverview from "./NavPetoverview.vue";
import NavTrainingplan from "./NavTrainingplan.vue";
import NavCareBlog from "./NavCareBlog.vue";
import NavMenuBreederProfile from "./NavMenuBreederProfile.vue";
import NavPetDiary from "./NavPetDiary.vue";
import NavMedicalDashboard from "./NavMedicalDashboard.vue";
import NavSpPassiveDashboard from "./NavSpPassiveDashboard.vue";
import NavContacts from "./NavContacts.vue";
import NavHealthLogOverview from './NavHealthLogOverview.vue';
import NavMedication from './NavMedication.vue';
import { mapActions, mapGetters } from "vuex";

export default {
  // "page" props from view file
  props: { data: {}, page: String },
  components: {
    NavNewpet,
    NavSharingsetting,
    NavAccountsetting,
    NavPetoverview,
    NavTrainingplan,
    NavCareBlog,
    NavMenuBreederProfile,
    NavPetDiary,
    NavMedicalDashboard,
    NavSpPassiveDashboard,
    NavContacts,
    NavHealthLogOverview,
    NavMedication
  },
  data() {
    return {};
  },
};
</script>

<style>
</style>