<template>
  <router-link :to="{ name: to, params: { view: view, data: data } }">
    <li
      class="w-max py-3 px-3 my-5 font-proximaMedium text-left cursor-pointer rounded-full border-transparent hover:bg-white hover:text-white flex items-center relative"
      :class="bgColor"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <span
        v-if="hover"
        class="w-max bg-primary py-3 pl-6 pr-16 rounded-full text-md text-white absolute right-2 transition ease-in-out"
        style="z-index: -1"
        >{{ title }}</span
      >
      <component
        class="z-20"
        :is="component"
        width="33"
        height="33"
        :iconColor="hoverComp"
      />
    </li>
  </router-link>
</template>

<script>
import OpenEye from "../../icons/OpenEye.vue";
import CareroutineIcon from "../../icons/CareroutineIcon.vue";
import DiaryIcon from "../../icons/DiaryIcon.vue";
import MedicalhistIcon from "../../icons/MedicalhistIcon.vue";
import SymptomIcon from "../../icons/SymptomIcon.vue";
import TrainingIcon from "../../icons/TrainingIcon.vue";
import TreatmentIcon from "../../icons/TreatmentIcon.vue";
import ShareIcon from "../../icons/ShareIcon.vue";
import SubscriptionIcon from "../../icons/SubscriptionIcon.vue";
import RoutineIcon from "../../icons/RoutineIcon.vue";
import NewentryIcon from "../../icons/NewentryIcon.vue";
import CaresheetsIcon from "../../icons/CaresheetsIcon.vue";
import AppointmentIcon from "../../icons/AppointmentIcon.vue";
import EditIcon from "../../icons/EditIcon.vue";
import TransferpetIcon from "../../icons/TransferpetIcon.vue";
import ReclaimpetIcon from "../../icons/ReclaimpetIcon.vue";
import RecentActivityIcon from "../../icons/RecentActivityIcon.vue";
import UpgradeIcon from "../../icons/UpgradeIcon.vue";
import SyringeIcon from "../../icons/SyringeIcon.vue";
import AppleIcon from "../../icons/AppleIcon.vue";
import PhotoGalleryIcon from "../../icons/PhotoGalleryIcon.vue"
import QuestionmarkIcon from "../../icons/QuestionmarkIcon.vue";
import MicIcon from "../../icons/MicIcon.vue";
import UserIcon from "../../icons/UserIcon.vue";
import ContactIcon from "../../icons/ContactIcon.vue";
import EmergencyIcon from "../../icons/EmergencyIcon.vue";
import SymptomManagerIcon from "../../icons/SymptomManagerIcon.vue";
import MedicationIcon from "../../icons/MedicationIcon.vue";
import VisualizedHealthIcon from "../../icons/VisualizedHealthIcon.vue";
import ParasiteIcon from "../../icons/ParasiteIcon.vue";

export default {
  props: {
    title: String,
    component: String,
    data: {},
    view: String,
    to: String,
    bgColor: {
      type: String,
      default: "bg-primary",
    },
  },
  components: {
    OpenEye,
    CareroutineIcon,
    DiaryIcon,
    MedicalhistIcon,
    SymptomIcon,
    TrainingIcon,
    TreatmentIcon,
    ShareIcon,
    SubscriptionIcon,
    RoutineIcon,
    NewentryIcon,
    CaresheetsIcon,
    AppointmentIcon,
    EditIcon,
    TransferpetIcon,
    ReclaimpetIcon,
    RecentActivityIcon,
    UpgradeIcon,
    SyringeIcon,
    UserIcon,
    EmergencyIcon,
    ContactIcon,
    AppleIcon,
    QuestionmarkIcon,
    MicIcon,
    PhotoGalleryIcon,
    SymptomManagerIcon,
    MedicationIcon,
    VisualizedHealthIcon,
    ParasiteIcon
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    hoverComp() {
      if (this.hover) {
        return "#0AC0CB";
      } else {
        return "#fff";
      }
    },
  },
};
</script>