<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 47 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_35764_9243)">
<!--       <circle cx="23.5" cy="23.5" r="23.5" fill="#525252" />
      <path
        d="M16.875 7.625H28.125L33.75 20.75L22.5 24.5L11.25 20.75L16.875 7.625Z"
        :stroke="iconColor"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.5 24.5L16.875 7.625"
        :stroke="iconColor"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.125 22.625L22.5 7.625"
        :stroke="iconColor"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.5 38.5625L16.875 41.375L17.8125 34.8125L14.0625 31.0625L19.6875 30.125L22.5 24.5L25.3125 30.125L30.9375 31.0625L27.1875 34.8125L28.125 41.375L22.5 38.5625Z"
        :stroke="iconColor"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="23.5" cy="23.5" r="23.5" fill="#525252" />
      <circle cx="23.5" cy="23.5" r="23.5" fill="#525252" /> -->
   <!--    <path
        d="M16.875 7.625H28.125L33.75 20.75L22.5 24.5L11.25 20.75L16.875 7.625Z"
        :stroke="iconColor"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.5 24.5L16.875 7.625"
        :stroke="iconColor"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.125 22.625L22.5 7.625"
        :stroke="iconColor"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.5 38.5625L16.875 41.375L17.8125 34.8125L14.0625 31.0625L19.6875 30.125L22.5 24.5L25.3125 30.125L30.9375 31.0625L27.1875 34.8125L28.125 41.375L22.5 38.5625Z"
        :stroke="iconColor"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="23.5" cy="23.5" r="23.5" fill="#525252" /> -->
      <path
        d="M32.1667 12.5H15.8333C14.5447 12.5 13.5 13.5447 13.5 14.8333V31.1667C13.5 32.4553 14.5447 33.5 15.8333 33.5H32.1667C33.4553 33.5 34.5 32.4553 34.5 31.1667V14.8333C34.5 13.5447 33.4553 12.5 32.1667 12.5Z"
        :stroke="iconColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.5013 21.8346C21.79 21.8346 22.8346 20.79 22.8346 19.5013C22.8346 18.2126 21.79 17.168 20.5013 17.168C19.2126 17.168 18.168 18.2126 18.168 19.5013C18.168 20.79 19.2126 21.8346 20.5013 21.8346Z"
        :stroke="iconColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M34.5 26.4983L30.8997 22.898C30.4621 22.4606 29.8687 22.2148 29.25 22.2148C28.6313 22.2148 28.0379 22.4606 27.6003 22.898L17 33.4983"
        :stroke="iconColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_35764_9243">
        <rect :width="width" :height="height" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
  
  <script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#059AA3"
    },
  },
};
</script>