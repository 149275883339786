<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 28 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.3307 22.3333C21.9436 22.3333 22.5504 22.2126 23.1166 21.9781C23.6828 21.7436 24.1972 21.3998 24.6306 20.9665C25.0639 20.5332 25.4076 20.0187 25.6422 19.4525C25.8767 18.8863 25.9974 18.2795 25.9974 17.6667C25.9974 17.0538 25.8767 16.447 25.6422 15.8808C25.4076 15.3146 25.0639 14.8002 24.6306 14.3668C24.1972 13.9335 23.6828 13.5898 23.1166 13.3552C22.5504 13.1207 21.9436 13 21.3307 13C20.0931 13 18.9061 13.4917 18.0309 14.3668C17.1557 15.242 16.6641 16.429 16.6641 17.6667C16.6641 18.9043 17.1557 20.0913 18.0309 20.9665C18.9061 21.8417 20.0931 22.3333 21.3307 22.3333Z"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2 22.3307V8.33073C2 7.71789 2.12071 7.11106 2.35523 6.54487C2.58975 5.97869 2.9335 5.46424 3.36684 5.0309C3.80018 4.59756 4.31462 4.25381 4.88081 4.01929C5.447 3.78477 6.05383 3.66406 6.66667 3.66406C7.2795 3.66406 7.88634 3.78477 8.45252 4.01929C9.01871 4.25381 9.53316 4.59756 9.9665 5.0309C10.3998 5.46424 10.7436 5.97869 10.9781 6.54487C11.2126 7.11106 11.3333 7.71789 11.3333 8.33073V22.3307"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2 14.3359H11.3333"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26 13V22.3333"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#059AA3",
    },
  },
};
</script>