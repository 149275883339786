<template>
  <div>
    <ul class="md:block fixed hidden top-36 right-5">
      <!--   <NavEach
        @click.native="routeRoutineOverview"
        :title="$t('pet_record_overview_routine_overview')"
        component="ShareIcon"
      /> -->
      <NavEach
        v-if="contacts != 0"
        @click.native="openBulkShareModals"
        :title="$t('pet_record_overview_bulk_share_records')"
        component="ShareIcon"
      />
    </ul>
    <div class="md:hidden">
      <span
        class="bg-white h-11 w-11 m-auto text-center pt-2 text-primary rounded-full text-xl absolute top-20 right-4 cursor-pointer"
        @click="mobileMenu = !mobileMenu"
        v-if="mobileMenu"
        >&#9650;</span
      >
      <span
        class="bg-white h-11 w-11 m-auto text-center pt-2 text-orange rounded-full text-xl absolute top-20 right-4 cursor-pointer"
        @click="mobileMenu = !mobileMenu"
        v-else
        >&#9660;</span
      >
      <transition name="fade">
        <ul v-if="mobileMenu" class="fixed top-32 right-3" :key="1">
          <!--  <NavEachMobile
        @click.native="routeRoutineOverview"
        :title="$t('pet_record_overview_routine_overview')"
        component="ShareIcon"
      /> -->
          <NavEachMobile
            @click.native="openBulkShareModals "
            :title="$t('pet_record_overview_bulk_share_records')"
            component="ShareIcon"
          />
        </ul>
      </transition>
    </div>

    <ModalsConfirm :show="bulkShareModalsComp" @close="closeBulkShareModals">
      <h1 slot="actionQuestion" class="text-2xl">
        {{ $t("pet_record_overview_bulk_share_records") }}
      </h1>
      <div
        slot="actionText"
        class="flex flex-col items-center justify-center text-sm font-proximaLight"
      >
        <span class="my-2"
          >Share all pet records at once, with specific contacts.
        </span>
        <div class="h-52 w-full md:w-9/12 overflow-scroll overflow-x-hidden">
          <div
            class="my-2 flex items-center justify-between"
            v-for="(contact, i) in contacts"
            :key="i"
          >
            <div class="flex items-center">
              <img
                class="h-6"
                src="@/assets/images/profilepic-icon.png"
                alt="profilepic-icon"
              />
              <span class="ml-2">{{ contact.contactEmailAddress }}</span>
            </div>
            <RadioOnoffBorder
              :val="contact"
              :checked="contact.checked"
              :checkAll="checkAll"
              @inputVal="inputVal"
            />
          </div>
        </div>
        <div class="w-10/12 md:w-9/12 mt-4 flex items-center justify-end">
          <!--  <label class="cont">
            <input type="checkbox" @change="checkAllFunc($event)" />
            <span class="checkmark"></span>
          </label>
          <span class="font-proximaLight text-md">Select all</span> -->
        </div>
      </div>
      <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        :title="$t('global_cancel')"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="closeBulkShareModals"
      />
      <SubmitButton
        slot="rightBtn"
        class="md:ml-1"
        :title="$t('global_confirm')"
        background="bg-success"
        paddingX="px-8"
        width="w-full"
        @click.native="submitContactModal"
      />
    </ModalsConfirm>

    <!--   <ModalsBulkShareRecords
      :title="$t('care_routine_bulk_share_records')"
      :show="bulkShareModals"
      @close="bulkShareModals = false"
      @addPet="submitAddPets"
    /> -->
    <!--   <ModalsConfirm
      :show="extServiceProviderModals"
      @close="extServiceProviderModals = false"
    >
      <h1 slot="actionQuestion" class="mt-4 text-2xl">Extend Sharing?</h1>
      <div
        slot="actionText"
        class="flex flex-col items-center justify-center text-sm font-proximaLight"
      >
        <span class="my-2"
          >Access to your pet’s care blog is about to expire. You have no free
          shares left. It will cost you €1 to extend sharing for another week.
        </span>
      </div>
      <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        :title="$t('global_decline')"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="extServiceProviderModals = false"
      />
      <SubmitButton
        slot="rightBtn"
        class="md:ml-1"
        title="$t('global_accept')"
        background="bg-success"
        paddingX="px-8"
        width="w-full"
        @click.native="submitExtProvider"
      />
    </ModalsConfirm> -->
  </div>
</template>

<script>
import NavEach from "./NavEach.vue";
import NavEachMobile from "./NavEachMobile.vue";
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import ModalsBulkShareRecords from "../../modals/ModalsBulkShareRecords.vue";
import DiaryIcon from "../../icons/DiaryIcon.vue";
import MedicalhistIcon from "../../icons/MedicalhistIcon.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import RadioOnoffBorder from "../../forms/RadioOnoffBorder.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    NavEach,
    NavEachMobile,
    ModalsConfirm,
    ModalsBulkShareRecords,
    DiaryIcon,
    MedicalhistIcon,
    SubmitButton,
    BorderedButton,
    RadioOnoffBorder,
  },
  data() {
    return {
      screen: false,
      mobileMenu: false,
      serviceProviderModals: false,
      extServiceProviderModals: false,
      extVetModals: false,
      checkAll: false,
      contactsList: [],
      pets: [],
    };
  },
  computed: {
    ...mapGetters({
      codeQR: "sharing/getCodeQR",
      contacts: "contact/getContacts",
      petRecords: "petRecord/getPetRecords",
    }),

    bulkShareModalsComp() {
      return this.$store.state.bulkShareModalsState;
    },
  },
  mounted() {
    const userEmail = localStorage.getItem("email");
    if (userEmail) {
      this.getContacts(userEmail);
      this.getPetRecords(userEmail);
    }
    
  },
  created() {
    window.mobileCheck = function () {
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return (this.screen = true);
    };
  },
  methods: {
    ...mapActions({
      getContacts: "contact/getContacts",
      shareByEmail: "sharing/shareByEmail",
      getPetRecords: "petRecord/getPetRecords",
    }),
    extServiceProvider() {
      this.serviceProviderModals = false;
      this.extServiceProviderModals = true;
    },
    extVet() {
      this.vetModals = false;
      this.extVetModals = true;
    },
    checkAllFunc(e) {
      // console.log(e.originalTarget.checked);
      this.checkAll = e.originalTarget.checked;
    },

    routeRoutineOverview() {
      this.$router.push("/routineoverview");
    },
    submitContactModal() {
      this.$store.commit("errorForm", "");

      const petR = this.petRecords.filter((item) => item.type == 1);
      petR.map((item) => {
        console.log(item);
        this.pets.push(item.petID);
      });
      console.log("9999999 : ", this.pets);

      const vals = this.contactsList.filter((p) => p.checked == true);
      vals.map((v) => {
        console.log(v);
        this.pets.map((item) => {
          const data = {
            newJoinerEmailAddress: v.contactEmailAddress,
            //newJoinerphoneNo: this.emailPhone,
            granted_by: localStorage.getItem("email"),
            petID: item,
            shareable_objects: [1],
            notes: "",
            expiration_date: "01/01/2050",
          };
         
          this.shareByEmail(data);
        });
      });

      this.$store.commit("bulkShareModals", false);
      console.log(vals, "-------------------");
    },
    checkAllFunc(e) {
      // console.log(e.originalTarget.checked);
      this.checkAll = e.originalTarget.checked;
    },
    inputVal(contact, checked) {
      contact.checked = checked;
      console.log(contact);

      const contacts = [...this.contacts];
      const index = contacts.indexOf(contact);
      contacts[index] = { ...contact };
      this.contactsList = contacts;
    },
    submitAddPets() {
      console.log("ok");
    },
   
    closeBulkShareModals() {
      this.$store.commit("bulkShareModals", false);
      this.checkAll = false;
    },
    openBulkShareModals() {
      this.$store.commit("bulkShareModals", true);
      this.checkAll = false;
    },
  },
};
</script>

<style scoped>
.cont {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 18px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.cont input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px #9ca3af solid;
}

/* On mouse-over, add a grey background color */
.cont:hover input ~ .checkmark {
  background-color: #ace5e1;
}
/* When the checkbox is checked, add a blue background */
.cont input:checked ~ .checkmark {
  background-color: #0ac0cb;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.cont input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.cont .checkmark:after {
  left: 6px;
  top: 3px;
  width: 7px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  transform: rotate(35deg);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>