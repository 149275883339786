<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 30 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 31H3.5C3.10218 31 2.72064 30.842 2.43934 30.5607C2.15804 30.2794 2 29.8978 2 29.5V20.5C2 20.1022 2.15804 19.7206 2.43934 19.4393C2.72064 19.158 3.10218 19 3.5 19H5.5C5.89782 19 6.27936 19.158 6.56066 19.4393C6.84196 19.7206 7 20.1022 7 20.5V29.5C7 29.8978 6.84196 30.2794 6.56066 30.5607C6.27936 30.842 5.89782 31 5.5 31Z"
      :fill="iconColor"
    />
    <path
      d="M19.5 31H17.5C17.1022 31 16.7206 30.842 16.4393 30.5607C16.158 30.2794 16 29.8978 16 29.5V14.5C16 14.1022 16.158 13.7206 16.4393 13.4393C16.7206 13.158 17.1022 13 17.5 13H19.5C19.8978 13 20.2794 13.158 20.5607 13.4393C20.842 13.7206 21 14.1022 21 14.5V29.5C21 29.8978 20.842 30.2794 20.5607 30.5607C20.2794 30.842 19.8978 31 19.5 31Z"
      :fill="iconColor"
    />
    <path
      d="M26.5 31H24.5C24.1022 31 23.7206 30.842 23.4393 30.5607C23.158 30.2794 23 29.8978 23 29.5V7.5C23 7.10218 23.158 6.72064 23.4393 6.43934C23.7206 6.15804 24.1022 6 24.5 6H26.5C26.8978 6 27.2794 6.15804 27.5607 6.43934C27.842 6.72064 28 7.10218 28 7.5V29.5C28 29.8978 27.842 30.2794 27.5607 30.5607C27.2794 30.842 26.8978 31 26.5 31Z"
      :fill="iconColor"
    />
    <path
      d="M12.5 31H10.5C10.1022 31 9.72064 30.842 9.43934 30.5607C9.15804 30.2794 9 29.8978 9 29.5V2.5C9 2.10218 9.15804 1.72064 9.43934 1.43934C9.72064 1.15804 10.1022 1 10.5 1H12.5C12.8978 1 13.2794 1.15804 13.5607 1.43934C13.842 1.72064 14 2.10218 14 2.5V29.5C14 29.8978 13.842 30.2794 13.5607 30.5607C13.2794 30.842 12.8978 31 12.5 31Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#059AA3",
    },
  },
};
</script>