<template>
  <svg
    width="28"
    height="31"
    viewBox="0 0 28 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 7.02041C17.984 6.83924 16 8.01948 14 8.01948C12 8.01948 10.016 6.83125 8 7.02041C4 7.39339 2 11.0167 2 17.0111C2 22.0065 6 29 8.95067 29C11.9 29 12.196 27.5001 14 27.5001C15.804 27.5001 16.1 28.9987 19.0493 28.9987C22 29 26 22.0065 26 17.0111C26 11.0167 24.1867 7.39472 20 7.02041Z"
      :stroke="iconColor"
      stroke-width="2"
    />
    <path
      d="M18.2436 4.19536C16.9317 5.50694 15.3933 5.88149 14.5714 5.99479C14.4962 6.00575 14.4194 5.99937 14.347 5.97613C14.2746 5.9529 14.2084 5.91342 14.1535 5.8607C14.0987 5.80799 14.0566 5.74344 14.0304 5.67196C14.0043 5.60049 13.9948 5.52399 14.0027 5.44829C14.1509 4.0429 14.7822 2.73263 15.7888 1.74147C17.1621 0.367234 18.6605 0.0619977 19.4557 0.00201667C19.5298 -0.0046629 19.6044 0.00529933 19.6742 0.0311759C19.7439 0.0570524 19.807 0.09819 19.8588 0.151585C19.9106 0.204979 19.9499 0.269282 19.9737 0.339799C19.9975 0.410315 20.0053 0.485265 19.9965 0.559174C19.8397 1.93467 19.2219 3.21643 18.2436 4.19536Z"
      :fill="iconColor"
    />
    <path
      d="M11.5 21C12.328 21 13 19.6573 13 18C13 16.3427 12.328 15 11.5 15C10.672 15 10 16.3427 10 18C10 19.6573 10.672 21 11.5 21Z"
      :fill="iconColor"
    />
    <path
      d="M16.5 21C17.328 21 18 19.6573 18 18C18 16.3427 17.328 15 16.5 15C15.672 15 15 16.3427 15 18C15 19.6573 15.672 21 16.5 21Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#0AC0CB",
    },
  },
};
</script>