<template>
  <div>
    <ul class="md:block fixed hidden top-36 right-5">
      <NavEach
        title="Contact Details"
        component="ContactIcon"
        @click.native="openContactDetailsModals"
      />
      <NavEach
        v-if="pendingContacts.length + contacts.length > 0"
        title="Emergency Contact"
        component="EmergencyIcon"
        @click.native="openAddEmergencyContactModals"
      />
      <NavEach
        title="Referral Program"
        component="UserIcon"
        to="ReferralProgram"
      />
    </ul>
    <div class="md:hidden">
      <span
        class="bg-white h-11 w-11 m-auto text-center pt-2 text-primary rounded-full text-xl absolute top-20 right-4 cursor-pointer"
        @click="mobileMenu = !mobileMenu"
        v-if="mobileMenu"
        >&#9650;</span
      >
      <span
        class="bg-white h-11 w-11 m-auto text-center pt-2 text-orange rounded-full text-xl absolute top-20 right-4 cursor-pointer"
        @click="mobileMenu = !mobileMenu"
        v-else
        >&#9660;</span
      >
      <transition name="fade">
        <ul v-if="mobileMenu" class="fixed top-32 right-3" :key="1">
          <NavEachMobile
            title="Contact Details"
            component="ContactIcon"
            @click.native="openContactDetailsModals"
          />
          <NavEachMobile
            title="Emergency Contact"
            component="EmergencyIcon"
            @click.native="openAddEmergencyContactModals"
          />
          <NavEachMobile
            title="Referral Program"
            component="UserIcon"
            to="ReferralProgram"
          />
          <NavEachMobile
            title="Contact Sync"
            component="ContactBookIcon"
            @click.native="openContactSyncModals"
          />
        </ul>
      </transition>
    </div>
    <ModalsConfirm
      :show="addEmergencyContactModalsComp"
      @close="closeAddEmergencyContactModals"
    >
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-6 -right-6 text-2xl font-proximaSemibold cursor-pointer"
          @click="closeAddEmergencyContactModals"
        >
          ✖
        </div>
        <h1
          class="mt-4 text-center text-subheaderText text-headerText text-2xl"
        >
          Add Emergency Contact
        </h1>
      </div>
      <div
        slot="actionText"
        class="mt-4 mb-6 flex flex-col items-center justify-center text-sm font-proximaLight"
      >
        <SubmitButton
          class="mt-2 mx-auto"
          marginT="mt-2"
          title="Contact List"
          background="bg-primary"
          paddingX="px-4"
          width="w-6/12"
          @click.native="openEmergencyContact"
        />
        <SubmitButton
          class="mt-2 mx-auto"
          marginT="mt-4"
          title="Email / Phone"
          background="bg-orange"
          paddingX="px-4"
          width="w-6/12"
          @click.native="openPhoneEmail"
        />
      </div>
      <div slot="leftBtn" class="mx-auto w-10/12 relative"></div>
      <div slot="rightBtn" class="mx-auto w-10/12 relative"></div>
    </ModalsConfirm>
    <ModalsConfirm
      :show="showAddPhoneEmailModalsComp"
      @close="closeAddPhoneEmailModals"
    >
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-6 -right-6 text-2xl font-proximaSemibold cursor-pointer"
          @click="closeAddPhoneEmailModals"
        >
          ✖
        </div>
        <img
          class="m-auto"
          src="@/assets/images/emailphone-icon.png"
          alt="profilepic-icon"
        />
        <h1
          class="mt-4 text-center text-subheaderText text-headerText text-2xl"
        >
          Add Emergency Contact
        </h1>
        <FormError :errors="errorForm" />
      </div>
      <div
        slot="actionText"
        class="mt-4 mb-6 flex flex-col items-center justify-center text-sm font-proximaLight"
      >
        <input
          type="text"
          placeholder="Name or label"
          class="w-8/12 ml-4 border-2 rounded-md px-3 py-3 border-primary"
          v-model="name"
        />
        <input
          type="text"
          placeholder="Phone number"
          class="w-8/12 ml-4 border-2 mt-5 rounded-md px-3 py-3 border-primary"
          v-model="contactphoneNo"
        />
        <input
          type="text"
          placeholder="Email address"
          class="w-8/12 ml-4 border-2 mt-5 rounded-md px-3 py-3 border-primary"
          v-model="contactEmailAddress"
        />
      </div>
      <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        :title="$t('global_cancel')"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="closeAddPhoneEmailModals"
      />
      <SubmitButton
        slot="rightBtn"
        class="md:ml-1"
        :title="$t('global_confirm')"
        background="bg-success"
        paddingX="px-8"
        width="w-full"
        @click.native="submitAddEmergencyContact"
      />
    </ModalsConfirm>
    <ModalsConfirm
      :show="emergencyContactModalsComp"
      @close="closeEmergencyContactModals"
    >
      <h1 slot="actionQuestion" class="text-2xl">Manage Emergency Contacts</h1>
      <div
        slot="actionText"
        class="flex flex-col items-center justify-center text-sm font-proximaLight"
      >
        <FormError :errors="errorForm" />
        <span class="my-2"
          >Choose whom you would like to be contacted in an emergency.
        </span>
        <div class="h-52 w-full md:w-9/12 overflow-scroll overflow-x-hidden">
          <div
            class="my-2 flex items-center justify-between"
            v-for="(contact, i) in contacts.filter(
              (item) => item.emergency_contact == true
            )"
            :key="i"
          >
            <div class="w-6/12 flex items-center">
              <img
                v-if="contact.profile_picture"
                class="h-6 rounded-full"
                :src="contact.profile_picture"
                alt="profilepic-icon"
              />
              <img
                v-else
                class="h-6"
                src="@/assets/images/profilepic-icon.png"
                alt="profilepic-icon"
              />
              <span class="ml-2">{{ contact.name || contact.name_label }}</span>
            </div>
            <RadioOnoffBorder
              :val="contact"
              :checked="contact.emergency_show_on_profile"
              @inputVal="inputVal"
              :checkAll="checkAll"
            />
          </div>
        </div>
        <div class="w-10/12 md:w-9/12 mt-4 flex items-center justify-end">
          <label class="cont">
            <input type="checkbox" @change="checkAllFunc()" />
            <span class="checkmark"></span>
          </label>
          <span class="font-proximaLight text-md">Select all</span>
        </div>
      </div>
      <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        :title="$t('global_cancel')"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="closeEmergencyContactModals"
      />
      <SubmitButton
        slot="rightBtn"
        class="md:ml-1"
        :title="$t('global_confirm')"
        background="bg-success"
        paddingX="px-8"
        width="w-full"
        @click.native="submitAddEmergencyContactList"
      />
    </ModalsConfirm>
    <ModalsConfirm
      :show="contactDetailsModalsComp"
      @close="closeContactDetailsModals"
    >
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute top-0 -right-6 text-2xl font-proximaSemibold cursor-pointer"
          @click="closeContactDetailsModals"
        >
          ✖
        </div>
        <div class="m-auto w-max bg-primary-20 rounded-full p-6">
          <ContactIcon iconColor="#0AC0CB" width="100px" height="100px" />
        </div>
        <h1 class="mt-4 text-center text-headerText text-2xl">
          Contact Details
        </h1>
        <FormError :errors="errorForm" />

        <h5 class="font-proximaLight text-subheaderText text-sm">
          These details are public to other users.
        </h5>
      </div>
      <div
        slot="actionText"
        class="mt-8 mb-6 flex flex-col items-center justify-center text-sm font-proximaLight"
      >
        <div class="w-full md:w-9/12 my-2 flex justify-between items-center">
          <div class="w-6/12 flex items-center">
            <div class="w-max bg-primary-20 rounded-full p-2">
              <WhatsappIcon iconColor="#0AC0CB" width="22px" height="22px" />
            </div>
            <span class="ml-1 text-paragraphText">Whatsapp</span>
          </div>
          <input
            v-model="data.whatsapp"
            type="text"
            class="w-full ml-4 border-2 border-lightGrey40 rounded-md px-2 py-1"
          />
        </div>
        <div class="w-full md:w-9/12 my-2 flex justify-between items-center">
          <div class="w-6/12 flex items-center">
            <div class="w-max bg-primary-20 rounded-full p-2">
              <FacebookIcon iconColor="#0AC0CB" width="22px" height="22px" />
            </div>
            <span class="ml-1 text-paragraphText">Facebook</span>
          </div>
          <input
            v-model="data.facebook"
            type="text"
            class="w-full ml-4 border-2 border-lightGrey40 rounded-md px-2 py-1"
          />
        </div>
        <div class="w-full md:w-9/12 my-2 flex justify-between items-center">
          <div class="w-6/12 flex items-center">
            <div class="w-max bg-primary-20 rounded-full p-2">
              <InstagramIcon iconColor="#0AC0CB" width="20px" height="20px" />
            </div>
            <span class="ml-1 text-paragraphText">Instagram</span>
          </div>
          <input
            v-model="data.instagram"
            type="text"
            class="w-full ml-4 border-2 border-lightGrey40 rounded-md px-2 py-1"
          />
        </div>
        <div class="w-full md:w-9/12 my-2 flex justify-between items-center">
          <div class="w-6/12 flex items-center">
            <div class="w-max bg-primary-20 rounded-full p-2">
              <TelegramIcon iconColor="#0AC0CB" width="20px" height="20px" />
            </div>
            <span class="ml-1 text-paragraphText">Telegram</span>
          </div>
          <input
            v-model="data.telegram"
            type="text"
            class="w-full ml-4 border-2 border-lightGrey40 rounded-md px-2 py-1"
          />
        </div>
        <div class="w-full md:w-9/12 my-2 flex justify-between items-center">
          <div class="w-6/12 flex items-center">
            <div class="w-max bg-primary-20 rounded-full p-2">
              <ViberIcon iconColor="#0AC0CB" width="20px" height="20px" />
            </div>
            <span class="ml-1 text-paragraphText">Viber</span>
          </div>
          <input
            v-model="data.viber"
            type="text"
            class="w-full ml-4 border-2 border-lightGrey40 rounded-md px-2 py-1"
          />
        </div>
        <div class="w-full md:w-9/12 my-2 flex justify-between items-center">
          <div class="w-6/12 flex items-center">
            <div class="w-max bg-primary-20 rounded-full p-2">
              <LineIcon iconColor="#0AC0CB" width="20px" height="20px" />
            </div>
            <span class="ml-1 text-paragraphText">Line</span>
          </div>
          <input
            v-model="data.line"
            type="text"
            class="w-full ml-4 border-2 border-lightGrey40 rounded-md px-2 py-1"
          />
        </div>
        <SubmitButton
          class="mt-8 mx-auto"
          marginT="mt-2"
          title="Save"
          background="bg-success"
          paddingX="px-4"
          width="w-4/12"
          @click.native="submitAddContact"
        />
      </div>
      <div slot="leftBtn" class="mx-auto w-10/12 relative"></div>
      <div slot="rightBtn" class="mx-auto w-10/12 relative"></div>
    </ModalsConfirm>
    <ModalsConfirm
      :show="showContactSyncModals"
      @close="closeShowContactSyncModals"
    >
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-6 -right-6 text-2xl font-proximaSemibold cursor-pointer"
          @click="closeShowContactSyncModals"
        >
          ✖
        </div>
        <!-- <img
          class="m-auto"
          src="@/assets/images/emailphone-icon.png"
          alt="emailphone-icon"
        /> -->
        <div class="w-max p-6 bg-primary-20 rounded-full m-auto">
          <contact-book-icon iconColor="#0AC0CB" :height="49" :width="49" />
        </div>
        <h1 class="mt-4 text-2xl text-subheaderText">Synchronize Contacts</h1>
        <FormError :errors="errorForm" />
      </div>
      <div slot="actionText">
        <p class="text-left text-subheaderText">
          If a contact already is a perPETual user, they will be automatically
          added to your friend list. <br /><br />
          If a contact is not a perPETual user and they have an email address,
          they can be sent a referral link.
        </p>
        <div
          class="mt-4 mb-6 text-left mx-auto w-full flex items-center justify-between relative"
        >
          <span class="font-proximaLight text-left text-sm"
            >Add as emergency contact?</span
          >
          <RadioOnoffBorder
            :checked="emergencyContact"
            @inputVal="emergencyContactVal"
          />
        </div>
        <div
          class="mt-4 mb-6 text-left mx-auto w-full flex items-center justify-between relative"
        >
          <span class="font-proximaLight text-left text-sm"
            >Invite to join perPETual?</span
          >
          <RadioOnoffBorder
            :checked="joinPerpetual"
            @inputVal="joinPerpetualVal"
          />
        </div>
      </div>
      <div slot="leftBtn" class="mx-auto w-10/12 relative">
        <BorderedButton
          class="mx-auto"
          marginT="mt-2"
          title="Cancel"
          paddingX="px-4"
          width="w-full"
          @click.native="closeShowContactSyncModals"
        />
      </div>
      <div slot="rightBtn" class="mx-auto w-10/12 relative">
        <SubmitButton
          class="mx-auto"
          marginT="mt-2"
          title="Confirm"
          background="bg-success"
          paddingX="px-4"
          width="w-full"
          @click.native="submitSyncContacts"
        />
      </div>
    </ModalsConfirm>
    <ModalsConfirm
      :show="showContactSuccessModals"
      @close="closeContactSuccessModals"
    >
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-6 -right-6 text-2xl font-proximaSemibold cursor-pointer"
          @click="closeContactSuccessModals"
        >
          ✖
        </div>
        <img
          class="m-auto w-20"
          src="@/assets/images/user-contact.png"
          alt="user-contact"
        />
        <FormError :errors="errorForm" />
      </div>
      <div slot="actionText">
        <div class="flex items-center">
          <UserIcon :width="18" :height="18" />
          <span class="pl-2 text-subheaderText"
            >1 friend(s) will be added to your friend list.</span
          >
        </div>
        <div class="flex items-center">
          <UserCheckIcon :width="18" :height="18" />
          <span class="pl-2 text-subheaderText"
            >You are inviting 1 friend(s) to connect!</span
          >
        </div>
        <div class="flex items-center">
          <GiftIcon :width="18" :height="18" />
          <span class="pl-2 text-subheaderText"
            >Successfully added 1 new referral(s)!</span
          >
        </div>
      </div>
      <div slot="leftBtn" class="mx-auto w-10/12 relative">
        <BorderedButton
          class="mx-auto"
          marginT="mt-2"
          title="Cancel"
          paddingX="px-4"
          width="w-full"
          @click.native="closeContactSuccessModals"
        />
      </div>
      <div slot="rightBtn" class="mx-auto w-10/12 relative">
        <SubmitButton
          class="mx-auto"
          marginT="mt-2"
          title="Confirm"
          background="bg-success"
          paddingX="px-4"
          width="w-full"
          @click.native="closeContactSuccessModals"
        />
      </div>
    </ModalsConfirm>
  </div>
</template>

<script>
import NavEach from "./NavEach.vue";
import NavEachMobile from "./NavEachMobile.vue";
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import RadioOnoffBorder from "../../forms/RadioOnoffBorder.vue";
import FormError from "../../warning/FormError.vue";

import ContactIcon from "../../icons/ContactIcon.vue";
import FacebookIcon from "../../icons/FacebookIcon.vue";
import InstagramIcon from "../../icons/InstagramIcon.vue";
import WhatsappIcon from "../../icons/WhatsappIcon.vue";
import TelegramIcon from "../../icons/TelegramIcon.vue";
import ViberIcon from "../../icons/ViberIcon.vue";
import LineIcon from "../../icons/LineIcon.vue";
import ContactBookIcon from "../../icons/ContactBookIcon.vue";
import UserCheckIcon from "../../icons/UserCheckIcon.vue";
import UserIcon from "../../icons/UserIcon.vue";
import GiftIcon from "../../icons/GiftIcon.vue";

import { mapActions, mapGetters } from "vuex";
import { validEmail } from "../../../methods/petMethods";

export default {
  components: {
    NavEach,
    NavEachMobile,
    ModalsConfirm,
    SubmitButton,
    BorderedButton,
    RadioOnoffBorder,
    ContactIcon,
    FacebookIcon,
    InstagramIcon,
    WhatsappIcon,
    TelegramIcon,
    ViberIcon,
    LineIcon,
    FormError,
    ContactBookIcon,
    UserCheckIcon,
    UserIcon,
    GiftIcon,
  },
  data() {
    return {
      data: {},
      mobileMenu: false,
      contactphoneNo: "",
      name: "",
      contactEmailAddress: "",
      screen: false,
      checkAll: false,
      showContactSyncModals: false,
      showContactSuccessModals: false,
      contactEmergency: [],
      emergencyContact: false,
      joinPerpetual: false,
      syncCalVal: { c: false },
      contactsMobil: [],
    };
  },
  watch: {
    contactDetails: function () {
      this.data = this.contactDetails;
    },
  },

  created() {
    window.mobileCheck = function () {
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return (this.screen = true);
    };

    const user = localStorage.getItem("email");
    if (user) {
      this.getContacts(user);
      this.getPendingContactsRequest(user);
      this.getContactDetails(user);
    }
  },

  computed: {
    ...mapGetters({
      contacts: "contact/getContacts",
      contactDetails: "contact/getContactDetails",
      pendingContacts: "contact/getPendingContactsRequest",
    }),
    contactEmergencyComp() {
      return this.$store.state.contacts
        ? this.$store.state.contacts.filter(
            (item) => item.emergency_contact == true
          )
        : [];
    },
    showAddPhoneEmailModalsComp() {
      return this.$store.state.showAddPhoneEmailModalsState;
    },
    emergencyContactModalsComp() {
      return this.$store.state.emergencyContactModalsState;
    },
    contactDetailsModalsComp() {
      return this.$store.state.contactDetailsModalsState;
    },
    contactDetailsComp() {
      return this.$store.state.contactDetailsState;
    },
    addEmergencyContactModalsComp() {
      return this.$store.state.addEmergencyContactModalsState;
    },
    errorForm() {
      return this.$store.state.errorForm;
    },
  },
  methods: {
    ...mapActions({
      getContacts: "contact/getContacts",
      getContactDetails: "contact/getContactDetails",
      getPendingContactsRequest: "contact/getPendingContactsRequest",
      addContact: "contact/addContact",
      confirmEmergencyContact: "contact/confirmEmergencyContact",
      showEmergencyContact: "contact/showEmergencyContact",
      hideEmergencyContact: "contact/hideEmergencyContact",
      addnewcontact: "contact/addnewcontact",
    }),
    emergencyContactVal(val, check) {
      this.emergencyContact = !this.emergencyContact;
      console.log(val, this.emergencyContact);
    },

    joinPerpetualVal(val, check) {
      this.joinPerpetual = !this.joinPerpetual;
      console.log(val, this.joinPerpetual);
    },
    submitAddContact() {
      this.addContact(this.data);
    },
    closeShowContactSyncModals() {
      this.showContactSyncModals = false;
    },
    closeContactSuccessModals() {
      this.showContactSuccessModals = false;
    },
    openContactSuccessModals() {
      this.closeShowContactSyncModals();
      this.showContactSuccessModals = true;
    },

    readContactsUsingContactsAPI() {
      navigator.contacts.select(["name", "tel"]).then((contactList) => {
        this.contactsMobil = contactList.map(
          (contact) => (
            {
              id: contact.id,
              name: contact.name[0]?.displayName,
              phone: contact.tel[0]?.value,
            },
            console.log(contact)
          )
        );
      });
    },
    showFallbackMessage() {
      alert(
        "Contacts API not supported. Please use a browser that supports it."
      );
    },

    submitSyncContacts() {
      const bodyData = {
        userEmailAddress: localStorage.getItem("email"), //this.owner.emailAddress,
        contactEmailAddress: this.contactEmailAddress.toLowerCase().trim(),
        contactphoneNo: this.contactPhoneNumber.trim(),
        name_label: this.contactName.trim(),
        emergency_contact: this.emergencyContact,
        emergency_status: this.emergencyContact ? 1 : 0,
        emergency_show_on_profile: false,
        referral: this.joinPerpetual,
      };
      console.log(bodyData);

      this.$store.commit("errorForm", "");

      const errors = this.validate();

      if (errors) return;
      this.addnewcontact(bodyData,0);

      //succes
      this.openContactSuccessModals();
    },
    submitAddEmergencyContact() {
      if (
        this.contactEmailAddress.trim() == "" &&
        this.contactphoneNo.trim() == ""
      ) {
        this.$store.commit(
          "errorForm",
          "Email address or Phone number is required"
        );
      } else {
        const req = {
          userEmailAddress: localStorage.getItem('email'),
          contactphoneNo: this.contactphoneNo,
          contactEmailAddress: this.contactEmailAddress,
          name_label: this.name,
          emergency_contact: 1,
          emergency_status: 2,
          emergency_show_on_profile: false,
          referral: 0,
        };

        console.log(req);
        this.addnewcontact(req,1);
      }
    },

    submitAddEmergencyContactList() {
      if (this.contactEmergency.length == 0) {
        this.$store.commit("errorForm", "Please choose at least one");
      } else {
        this.contactEmergency.map((item) =>
          item.emergency_show_on_profile == true
            ? this.showEmergencyContact(item.id)
            : this.hideEmergencyContact(item.id)
        );
      }
    },

    async inputVal(val, checked) {
      val.emergency_show_on_profile = !val.emergency_show_on_profile;

      this.contactEmergency = [...this.contactEmergency, val]; //add contact when it is false or true
      console.log(this.contactEmergency);
      /*  val.emergency_show_on_profile
        ? (this.contactEmergency = [...this.contactEmergency, val])
        : (this.contactEmergency = this.contactEmergency.filter(
            (item) => val.id !== item.id
          )); */
    },

    openAddDiaryModals() {
      this.$store.commit("careBlogDiaryModals", true);
    },
    openAddAppointmentModals() {
      this.$store.commit("careBlogAppointmentModals", true);
    },
    checkAllFunc() {
      this.checkAll = !this.checkAll;
      this.contactEmergency = [];
      if (this.checkAll) {
        this.contacts.map((item) => {
          item.emergency_show_on_profile = this.checkAll;
          this.contactEmergency = [...this.contactEmergency, item];
        });
      }
      console.log(this.contactEmergency);
    },
    openEmergencyContact() {
      this.$store.commit("addEmergencyContactModals", false);
      this.$store.commit("emergencyContactModals", true);
    },
    closeEmergencyContactModals() {
      this.$store.commit("emergencyContactModals", false);
    },
    openPhoneEmail() {
      this.contactphoneNo = "";
      this.contactEmailAddress = "";
      this.name = "";
      this.$store.commit("errorForm", "");
      this.$store.commit("addEmergencyContactModals", false);
      this.$store.commit("showAddPhoneEmailModals", true);
    },
    closeAddPhoneEmailModals() {
      this.$store.commit("showAddPhoneEmailModals", false);
    },
    closeContactDetailsModals() {
      this.$store.commit("contactDetailsModals", false);
    },
    openContactDetailsModals() {
      this.$store.commit("contactDetailsModals", true);
    },

    openContactSyncModals() {
      this.showContactSyncModals = true;

      if ("contactsMobil" in navigator && "ContactsManager" in window) {
        this.readContactsUsingContactsAPI();
      } else {
        // Fallback for browsers that do not support Contacts API
        this.showFallbackMessage();
      }
    },
    openAddEmergencyContactModals() {
      //this.$store.commit("addEmergencyContactModals", true);
      this.$store.commit("emergencyContactModals", true);
    },
    closeAddEmergencyContactModals() {
      this.$store.commit("addEmergencyContactModals", false);
    },
    changeMobileMenu() {
      //this.mobileMenu = !this.mobileMenu;
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
