<template>
    <svg :width="width" :height="height" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 12.002V18.002L21 21.002" :stroke="iconColor" stroke-width="1.66667" stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M4.57812 16.503C4.91426 13.2031 6.45352 10.1425 8.90222 7.90498C11.3509 5.66751 14.5377 4.40986 17.8544 4.37198C21.1712 4.33409 24.3859 5.51863 26.885 7.69958C29.3842 9.88054 30.993 12.9052 31.4044 16.1966C31.8158 19.488 31.0011 22.8156 29.1157 25.5447C27.2302 28.2737 24.4061 30.2131 21.182 30.9928C17.958 31.7725 14.5597 31.338 11.6356 29.7721C8.7115 28.2063 6.46621 25.6187 5.32812 22.503"
            :stroke="iconColor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M4.57812 30.0039V22.5039H12.0781" :stroke="iconColor" stroke-width="1.66667" stroke-linecap="round"
            stroke-linejoin="round" />
    </svg>
</template>
  
<script>
export default {
    props: {
        width: {
            type: [Number, String],
            default: 36,
        },
        height: {
            type: [Number, String],
            default: 36,
        },
        iconColor: {
            type: String,
            default: "#354E57",
        },
    },
};
</script>