<template>
  <div>
    <ul class="md:block fixed hidden top-36 right-5">
      <NavEach
        title="Symptom Manager"
        component="SymptomManagerIcon"
        @click.native="openSymptomManagerModals"
      />
      <NavEach
        title="Medication"
        component="MedicationIcon"
        @click.native="gotoMedication"
      />
      <NavEach
        v-if="compOpenNavHealthv"
        title="Visualized Health"
        component="VisualizedHealthIcon"
        @click.native="gotoVisualizedHealth"
      />
    </ul>
    <div class="md:hidden">
      <span
        class="bg-white h-11 w-11 m-auto text-center pt-2 text-primary rounded-full text-xl absolute top-20 right-4 cursor-pointer"
        @click="mobileMenu = !mobileMenu"
        v-if="mobileMenu"
        >&#9650;</span
      >
      <span
        class="bg-white h-11 w-11 m-auto text-center pt-2 text-orange rounded-full text-xl absolute top-20 right-4 cursor-pointer"
        @click="mobileMenu = !mobileMenu"
        v-else
        >&#9660;</span
      >
      <transition name="fade">
        <ul v-if="mobileMenu" class="fixed top-32 right-3" :key="1">
          <NavEachMobile
            title="Symptom Manager"
            component="SymptomManagerIcon"
          />
          <NavEachMobile
            title="Medication"
            component="MedicationIcon"
            @click.native="gotoMedication"
          />
          <NavEachMobile
            v-if="compOpenNavHealthv"
            title="Visualized Health"
            component="VisualizedHealthIcon"
            @click.native="gotoVisualizedHealth"
          />
        </ul>
      </transition>
    </div>

    <!-- <ModalsConfirm :show="helpModals" @close="helpModals = false">
      <div slot="actionQuestion" class="mt-4 text-2xl relative">
        <div
          class="absolute -top-5 -right-5 text-2xl font-proximaSemibold cursor-pointer"
          @click="helpModals = false"
        >
          ✖
        </div>
        <div class="w-max m-auto p-2 bg-orange rounded-full">
          <QuestionmarkIcon :height="38" :width="38" />
        </div>
        <h3 class="mt-2 text-subheaderText">
          Interest Groups & Blood Donation
        </h3>
      </div>
      <div
        slot="actionText"
        class="flex flex-col items-center justify-center text-sm font-proximaLight"
      >
        <p class="my-2 text-left">
          <b>Interest groups</b> can be based on diagnosed diseases, breed, or
          breeder progeny. If you opt in, you may be contacted by others within
          the same group from the contact screen under the “Interest Group” tab.
          <br /><br />
          Only dogs and cats can be <b>blood donors</b>. If your pet
          prequalifies, you will be able to add it to the blood donor database.
          If you choose to participate (toggle “on”), a qualification
          questionnaire modal will appear. If your pet passes, you will receive
          notifications when it can donate blood to another.
        </p>
      </div>
      <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        :title="$t('global_decline')"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="helpModals = false"
      />
      <SubmitButton
        slot="rightBtn"
        class="md:ml-1"
        :title="$t('global_accept')"
        background="bg-success"
        paddingX="px-8"
        width="w-full"
        @click.native="submitExtProvider"
      />
    </ModalsConfirm> -->
  </div>
</template>

<script>
import NavEach from "./NavEach.vue";
import NavEachMobile from "./NavEachMobile.vue";
import ModalsConfirm from "../../modals/ModalsConfirm.vue";

export default {
  components: {
    NavEach,
    NavEachMobile,
    ModalsConfirm,
  },
  data() {
    return {
      screen: false,
      helpModals: false,
      mobileMenu: false,
      role: localStorage.getItem("role"),
    };
  },
  computed: {
    compOpenNavHealthv() {
      return this.$store.state.openNavHealthVState;
    },
  },
  created() {
    window.mobileCheck = function () {
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return (this.screen = true);
    };
  },
  methods: {
    gotoMedication() {
      this.$router.push({ path: "/healthlogoverview/medication" });
    },
    gotoVisualizedHealth() {
      this.$router.push({ path: "/healthlogoverview/visualizedhealth" });
    },
    openSymptomManagerModals() {
      this.$store.commit("symptomManagerModals", true);
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>