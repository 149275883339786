<template>


  <svg :width="width" :height="height" viewBox="0 0 21 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.0121382 19.7544L1.49348 30.1916C1.56351 30.6931 1.82227 31.1532 2.22148 31.4859C2.6207 31.8187 3.1331 32.0014 3.66306 32H17.336L0.0121382 19.7544ZM0.0121382 19.7544L0.0122133 19.7544L0.0121382 19.7544ZM17.3371 31.5H17.336L3.66306 31.5L3.66175 31.5C3.24668 31.5011 2.84869 31.3578 2.54162 31.1018C2.23509 30.8463 2.04102 30.4974 1.98868 30.1225L1.98852 30.1214L0.507178 19.6842L0.507066 19.6834C0.485741 19.5348 0.512823 19.3826 0.586737 19.2467C0.660857 19.1104 0.779687 18.9954 0.930503 18.9211C1.08154 18.8466 1.25489 18.8179 1.42591 18.8407C1.59686 18.8634 1.7532 18.9358 1.87433 19.0443L1.87432 19.0444L1.87699 19.0467L4.31811 21.2022C4.4966 21.3658 4.70685 21.4917 4.93546 21.5735C5.1659 21.656 5.41095 21.6921 5.65564 21.6801C5.90033 21.6681 6.14057 21.6082 6.36158 21.5033C6.58231 21.3986 6.77985 21.2509 6.94111 21.0683C6.94133 21.068 6.94155 21.0678 6.94177 21.0675L9.86396 17.7775L9.86513 17.7762C9.93968 17.6917 10.0342 17.6221 10.1429 17.5738C10.2517 17.5254 10.3713 17.5 10.493 17.5C10.6147 17.5 10.7343 17.5254 10.8431 17.5738C10.9518 17.6221 11.0463 17.6917 11.1208 17.7762L11.1208 17.7762L11.1227 17.7783L14.0583 21.0687C14.0585 21.0689 14.0586 21.069 14.0587 21.0692C14.2185 21.2491 14.4136 21.3948 14.6314 21.4987C14.8493 21.6027 15.0861 21.6629 15.3276 21.6764C15.569 21.69 15.8112 21.6567 16.0396 21.5781C16.2678 21.4996 16.4785 21.3772 16.6583 21.2173C16.6586 21.2171 16.6588 21.2169 16.659 21.2167L19.1024 19.0592L19.1078 19.0545L19.1131 19.0495C19.2328 18.9375 19.3893 18.8615 19.5617 18.8361C19.7342 18.8106 19.9099 18.8376 20.0634 18.9117C20.2167 18.9858 20.3375 19.1016 20.4127 19.2393C20.4877 19.3766 20.5148 19.5306 20.4924 19.6807L20.4919 19.6841L19.0105 30.1151L19.0102 30.1178C18.9589 30.4935 18.7654 30.8436 18.4588 31.1C18.1516 31.3569 17.753 31.5009 17.3371 31.5Z"
      :fill="iconColor" :stroke="iconColor" />
    <path
      d="M14.8976 9.7896C14.8976 9.91231 14.8608 10.03 14.7953 10.1168C14.7299 10.2035 14.6411 10.2523 14.5485 10.2523H4.22195L6.41433 13.1672C6.45434 13.2069 6.48744 13.2577 6.51138 13.3159C6.53532 13.3741 6.54955 13.4384 6.55309 13.5045C6.55664 13.5706 6.54942 13.637 6.53193 13.6991C6.51444 13.7612 6.48709 13.8176 6.45172 13.8645C6.41635 13.9114 6.3738 13.9476 6.32694 13.9708C6.28008 13.994 6.23001 14.0035 6.18012 13.9988C6.13023 13.9941 6.08168 13.9753 6.03776 13.9436C5.99385 13.9118 5.95559 13.868 5.92558 13.8149L3.13274 10.1135C3.08706 10.0798 3.05045 10.028 3.02801 9.96542C3.00954 9.91003 3 9.85015 3 9.7896C3 9.72905 3.00954 9.66916 3.02801 9.61378C3.0429 9.55603 3.06946 9.50482 3.10481 9.46572C3.13735 9.39883 3.18689 9.34959 3.24445 9.32692C3.28806 9.31315 3.3335 9.31315 3.37711 9.32692H14.5485C14.6411 9.32692 14.7299 9.37566 14.7953 9.46243C14.8608 9.5492 14.8976 9.66689 14.8976 9.7896ZM16.9922 5.16278C17.0026 5.22058 17.0026 5.2808 16.9922 5.3386C16.955 5.45003 16.8878 5.5391 16.8037 5.58845C16.7601 5.60221 16.7146 5.60221 16.671 5.58845H5.47174C5.37916 5.58845 5.29036 5.5397 5.22489 5.45293C5.15942 5.36616 5.12264 5.24848 5.12264 5.12577C5.12264 5.00305 5.15942 4.88537 5.22489 4.7986C5.29036 4.71183 5.37916 4.66308 5.47174 4.66308H15.7983L13.6059 1.7852C13.5733 1.74283 13.5473 1.6923 13.5295 1.63655C13.5117 1.58079 13.5024 1.52091 13.5022 1.46037C13.502 1.39983 13.5108 1.33983 13.5282 1.28383C13.5455 1.22784 13.5711 1.17697 13.6034 1.13416C13.6357 1.09135 13.6741 1.05745 13.7163 1.03443C13.7586 1.0114 13.8039 0.9997 13.8495 1.00001C13.8952 1.00031 13.9404 1.01262 13.9825 1.03621C14.0245 1.0598 14.0627 1.09421 14.0946 1.13745L16.8875 4.8389C16.9228 4.878 16.9494 4.92922 16.9643 4.98696C16.9841 5.04176 16.9937 5.10206 16.9922 5.16278Z"
      :fill="iconColor" :stroke="iconColor" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#354E57",
    },
  },
};
</script>