<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.2181 28.875H6.875C6.14565 28.875 5.44618 28.5853 4.93046 28.0695C4.41473 27.5538 4.125 26.8543 4.125 26.125V9.625C4.125 8.89565 4.41473 8.19618 4.93046 7.68046C5.44618 7.16473 6.14565 6.875 6.875 6.875H23.375C24.1043 6.875 24.8038 7.16473 25.3195 7.68046C25.8353 8.19618 26.125 8.89565 26.125 9.625V15.125"
      :stroke="iconColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.75 30.25C26.2087 30.25 27.6076 29.6705 28.6391 28.6391C29.6705 27.6076 30.25 26.2087 30.25 24.75C30.25 23.2913 29.6705 21.8924 28.6391 20.8609C27.6076 19.8295 26.2087 19.25 24.75 19.25C23.2913 19.25 21.8924 19.8295 20.8609 20.8609C19.8295 21.8924 19.25 23.2913 19.25 24.75C19.25 26.2087 19.8295 27.6076 20.8609 28.6391C21.8924 29.6705 23.2913 30.25 24.75 30.25Z"
      :stroke="iconColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.625 4.125V9.625"
      :stroke="iconColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.625 4.125V9.625"
      :stroke="iconColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.125 15.125H26.125"
      :stroke="iconColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.75 22.6758V24.7438L26.125 26.1188"
      :stroke="iconColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#354E57",
    },
  },
};
</script>