<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.8796 9.11902C23.0396 3.19902 15.1996 1.43902 9.11965 5.11902C3.19965 8.79902 1.27965 16.799 5.11965 22.719L5.43965 23.199L4.15965 27.999L8.95965 26.719L9.43965 27.039C11.5196 28.159 13.7596 28.799 15.9996 28.799C18.3996 28.799 20.7996 28.159 22.8796 26.879C28.7996 23.039 30.5596 15.199 26.8796 9.11902ZM23.5196 21.439C22.8796 22.399 22.0796 23.039 20.9596 23.199C20.3196 23.199 19.5196 23.519 16.3196 22.239C13.5996 20.959 11.3596 18.879 9.75965 16.479C8.79965 15.359 8.31965 13.919 8.15965 12.479C8.15965 11.199 8.63965 10.079 9.43965 9.27902C9.75965 8.95902 10.0796 8.79902 10.3996 8.79902H11.1996C11.5196 8.79902 11.8396 8.79902 11.9996 9.43902C12.3196 10.239 13.1196 12.159 13.1196 12.319C13.2796 12.479 13.2796 12.799 13.1196 12.959C13.2796 13.279 13.1196 13.599 12.9596 13.759C12.7996 13.919 12.6396 14.239 12.4796 14.399C12.1596 14.559 11.9996 14.879 12.1596 15.199C12.7996 16.159 13.5996 17.119 14.3996 17.919C15.3596 18.719 16.3196 19.359 17.4396 19.839C17.7596 19.999 18.0796 19.999 18.2396 19.679C18.3996 19.359 19.1996 18.559 19.5196 18.239C19.8396 17.919 19.9996 17.919 20.3196 18.079L22.8796 19.359C23.1996 19.519 23.5196 19.679 23.6796 19.839C23.8396 20.319 23.8396 20.959 23.5196 21.439Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#354E57",
    },
  },
};
</script>