<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.3345 24.6061C25.3345 24.9376 25.0565 25.217 24.7261 25.217L5.71405 25.2158C5.37855 25.2158 5.10566 24.9421 5.10566 24.6049V22.303H6.06542C6.90266 22.303 7.58473 21.6184 7.58473 20.7775V20.4553C7.58473 19.6146 6.90296 18.9297 6.06542 18.9297H5.10566V15.5279H6.06542C6.90266 15.5279 7.58473 14.8433 7.58473 14.0023V13.6802C7.58473 12.8395 6.90296 12.1546 6.06542 12.1546H5.10566V8.75396H6.06542C6.90266 8.75396 7.58473 8.06938 7.58473 7.22749V6.90535C7.58473 6.06466 6.90296 5.37978 6.06542 5.37978H5.10566V3.07909C5.10566 2.74221 5.37825 2.4682 5.71405 2.4682H24.7264C25.0565 2.4682 25.3348 2.74733 25.3348 3.07909L25.3345 24.6061ZM3.49978 21.6548C3.01721 21.6548 2.6263 21.2611 2.6263 20.7778V20.4556C2.6263 19.9711 3.01841 19.5785 3.49978 19.5785H6.06632C6.54889 19.5785 6.9398 19.9723 6.9398 20.4556V20.7778C6.9398 21.2623 6.54769 21.6548 6.06632 21.6548H3.49978ZM3.49978 14.8815C3.01721 14.8815 2.6263 14.4878 2.6263 14.0044V13.6823C2.6263 13.1977 3.01841 12.8052 3.49978 12.8052H6.06632C6.54889 12.8052 6.9398 13.1989 6.9398 13.6823V14.0044C6.9398 14.489 6.54769 14.8815 6.06632 14.8815H3.49978ZM3.49978 8.10698C3.01721 8.10698 2.6263 7.71325 2.6263 7.22989V6.90775C2.6263 6.42319 3.01841 6.03067 3.49978 6.03067H6.06632C6.54889 6.03067 6.9398 6.4244 6.9398 6.90775V7.22989C6.9398 7.71445 6.54769 8.10698 6.06632 8.10698H3.49978ZM24.7264 1.82031H5.71435C5.02299 1.82031 4.46043 2.38518 4.46043 3.07939V5.38128H3.49978C2.66224 5.38128 1.98047 6.06616 1.98047 6.90685V7.22899C1.98047 8.07088 2.66224 8.75546 3.49978 8.75546H4.45954V12.1561H3.49978C2.66254 12.1561 1.98047 12.8407 1.98047 13.6817V14.0038C1.98047 14.8445 2.66224 15.5294 3.49978 15.5294H4.45954V18.9313H3.49978C2.66254 18.9313 1.98047 19.6158 1.98047 20.4568V20.779C1.98047 21.6196 2.66224 22.3045 3.49978 22.3045H4.45954V24.6064C4.45954 25.3006 5.02209 25.8655 5.71345 25.8655H24.7255C25.4169 25.8655 25.9794 25.3006 25.9794 24.6064L25.9806 3.08059C25.9803 2.38518 25.4178 1.82031 24.7264 1.82031Z"
      :fill="iconColor"
      :stroke="iconColor"
    />
    <path
      d="M13.461 10.7731C13.461 9.52571 14.4711 8.51177 15.713 8.51177C16.955 8.51177 17.9651 9.52601 17.9651 10.7731C17.9651 12.0201 16.955 13.0343 15.713 13.0343C14.4711 13.0343 13.461 12.0201 13.461 10.7731ZM18.6109 10.7731C18.6109 9.16838 17.3111 7.86328 15.713 7.86328C14.115 7.86328 12.8164 9.16838 12.8164 10.7731C12.8164 12.3777 14.1161 13.6828 15.713 13.6828C17.3099 13.6828 18.6109 12.3768 18.6109 10.7731Z"
      :fill="iconColor"
      :stroke="iconColor"
    />
    <path
      d="M19.4208 19.1711H12.0061V17.5685C12.0061 16.0944 13.2004 14.8943 14.6685 14.8943H16.7575C18.2256 14.8943 19.4208 16.0935 19.4208 17.5685V19.1711ZM16.7575 14.2461H14.6685C12.8445 14.2461 11.3594 15.7362 11.3594 17.5688V19.4945C11.3594 19.6731 11.5044 19.8187 11.6823 19.8187H19.7426C19.9205 19.8187 20.0655 19.6731 20.0655 19.4945V17.5688C20.0667 15.7374 18.5824 14.2461 16.7575 14.2461Z"
      :fill="iconColor"
      :stroke="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#354E57",
    },
  },
};
</script>