<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_32651_6852)">
      <path
        d="M17.5 2.1875C14.4715 2.1875 11.511 3.08556 8.99284 4.76812C6.47471 6.45068 4.51207 8.84217 3.3531 11.6402C2.19413 14.4382 1.8909 17.517 2.48173 20.4873C3.07257 23.4577 4.53094 26.1861 6.67243 28.3276C8.81392 30.4691 11.5424 31.9274 14.5127 32.5183C17.483 33.1091 20.5619 32.8059 23.3598 31.6469C26.1578 30.4879 28.5493 28.5253 30.2319 26.0072C31.9144 23.489 32.8125 20.5285 32.8125 17.5C32.8125 13.4389 31.1992 9.54408 28.3276 6.67243C25.4559 3.80078 21.5611 2.1875 17.5 2.1875ZM17.5 30.625C14.9041 30.625 12.3665 29.8552 10.2081 28.413C8.04975 26.9708 6.36749 24.921 5.37409 22.5227C4.38069 20.1244 4.12077 17.4854 4.6272 14.9394C5.13363 12.3934 6.38367 10.0548 8.21923 8.21922C10.0548 6.38366 12.3934 5.13362 14.9394 4.62719C17.4854 4.12076 20.1244 4.38068 22.5227 5.37408C24.921 6.36748 26.9708 8.04975 28.413 10.2081C29.8552 12.3665 30.625 14.9041 30.625 17.5C30.625 20.981 29.2422 24.3194 26.7808 26.7808C24.3194 29.2422 20.981 30.625 17.5 30.625Z"
        :fill="iconColor"
      />
      <path
        d="M17.5 27.3438C18.4061 27.3438 19.1406 26.6092 19.1406 25.7031C19.1406 24.797 18.4061 24.0625 17.5 24.0625C16.5939 24.0625 15.8594 24.797 15.8594 25.7031C15.8594 26.6092 16.5939 27.3438 17.5 27.3438Z"
        :fill="iconColor"
      />
      <path
        d="M18.5938 8.75001H16.9531C16.3064 8.74857 15.6657 8.87489 15.0679 9.12173C14.4701 9.36857 13.927 9.73105 13.4696 10.1884C13.0123 10.6457 12.6498 11.1889 12.403 11.7867C12.1561 12.3845 12.0298 13.0251 12.0313 13.6719V14.2188H14.2188V13.6719C14.2188 12.9467 14.5068 12.2512 15.0196 11.7384C15.5324 11.2256 16.2279 10.9375 16.9531 10.9375H18.5938C19.319 10.9375 20.0145 11.2256 20.5273 11.7384C21.0401 12.2512 21.3281 12.9467 21.3281 13.6719C21.3281 14.3971 21.0401 15.0926 20.5273 15.6054C20.0145 16.1182 19.319 16.4063 18.5938 16.4063H16.4063V21.3281H18.5938V18.5938C19.8991 18.5938 21.151 18.0752 22.0741 17.1522C22.9971 16.2291 23.5156 14.9772 23.5156 13.6719C23.5156 12.3665 22.9971 11.1146 22.0741 10.1916C21.151 9.26857 19.8991 8.75001 18.5938 8.75001Z"
        :fill="iconColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_32651_6852">
        <rect width="35" height="35" :fill="iconColor" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "#ffffff",
    },
  },
};
</script>