<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8749 7.70837H10.7916C9.97383 7.70837 9.18958 8.03322 8.61134 8.61146C8.0331 9.1897 7.70825 9.97396 7.70825 10.7917V29.2917C7.70825 30.1095 8.0331 30.8937 8.61134 31.472C9.18958 32.0502 9.97383 32.375 10.7916 32.375H26.2083C27.026 32.375 27.8103 32.0502 28.3885 31.472C28.9667 30.8937 29.2916 30.1095 29.2916 29.2917V10.7917C29.2916 9.97396 28.9667 9.1897 28.3885 8.61146C27.8103 8.03322 27.026 7.70837 26.2083 7.70837H23.1249"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.0417 4.625H16.9583C16.1406 4.625 15.3563 4.94985 14.7781 5.52809C14.1999 6.10632 13.875 6.89058 13.875 7.70833C13.875 8.52608 14.1999 9.31034 14.7781 9.88858C15.3563 10.4668 16.1406 10.7917 16.9583 10.7917H20.0417C20.8594 10.7917 21.6437 10.4668 22.2219 9.88858C22.8001 9.31034 23.125 8.52608 23.125 7.70833C23.125 6.89058 22.8001 6.10632 22.2219 5.52809C21.6437 4.94985 20.8594 4.625 20.0417 4.625Z"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.4167 21.5834H21.5834"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.5 18.5V24.6667"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#059AA3",
    },
  },
};
</script>