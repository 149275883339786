<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.4166 15.5H24.2499L20.3749 27.125L12.6249 3.875L8.74992 15.5H3.58325"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle cx="16.5" cy="16.5" r="15.75" :stroke="iconColor" stroke-width="1.5" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#059AA3",
    },
  },
};
</script>