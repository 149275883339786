<template>
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_35768_9710)">
      <path
        d="M21.207 -0.375L27.3737 5.79167"
        :stroke="iconColor"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.293 2.70898L17.3555 9.64648"
        :stroke="iconColor"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.7305 5.02148L21.9805 14.2715"
        :stroke="iconColor"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.4375 12.7292L10.4167 22.75H4.25V16.5833L14.2708 6.5625"
        :stroke="iconColor"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.5625 14.2715L8.875 16.584"
        :stroke="iconColor"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.1875 9.64648L13.5 11.959"
        :stroke="iconColor"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M-0.375 27.375L4.25 22.75"
        :stroke="iconColor"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_35768_9710">
        <rect width="27" height="27" :fill="iconColor" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#fff",
    },
  },
};
</script>