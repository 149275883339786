<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.102 14.1807C15.5972 14.1807 16 14.5887 16 15.0903C16 15.5929 15.5972 16 15.102 16H10.0264C9.53118 16 9.12842 15.5929 9.12842 15.0903C9.12842 14.5887 9.53118 14.1807 10.0264 14.1807H15.102ZM11.5821 0.62139L12.8932 1.66292C13.4309 2.08335 13.7893 2.63756 13.9119 3.22044C14.0534 3.8616 13.9025 4.49129 13.478 5.03595L5.66791 15.1359C5.30947 15.5946 4.78125 15.8526 4.2153 15.8621L1.10257 15.9003C0.932788 15.9003 0.7913 15.7857 0.75357 15.6232L0.046132 12.556C-0.0764907 11.9922 0.046132 11.4093 0.404567 10.9602L5.94145 3.79375C6.03578 3.67909 6.20556 3.66094 6.31875 3.74598L8.64858 5.59971C8.7995 5.72393 9.00702 5.79082 9.22397 5.76215C9.68616 5.70482 9.99743 5.28438 9.95027 4.83528C9.92197 4.60596 9.80878 4.41485 9.65786 4.27152C9.6107 4.2333 7.39406 2.45601 7.39406 2.45601C7.25257 2.34135 7.22427 2.13113 7.33746 1.98876L8.21469 0.850717C9.02588 -0.190812 10.4408 -0.286365 11.5821 0.62139Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#354E57",
    },
  },
};
</script>