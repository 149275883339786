<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.0696 3.99458C25.7486 4.01793 25.4335 4.09325 25.1366 4.21758H25.1326C24.8476 4.33058 23.4926 4.90058 21.4326 5.76458L14.0506 8.87358C8.75363 11.1036 3.54663 13.2996 3.54663 13.2996L3.60863 13.2756C3.60863 13.2756 3.24963 13.3936 2.87463 13.6506C2.64296 13.798 2.4436 13.9909 2.28863 14.2176C2.10463 14.4876 1.95663 14.9006 2.01163 15.3276C2.10163 16.0496 2.56963 16.4826 2.90563 16.7216C3.24563 16.9636 3.56963 17.0766 3.56963 17.0766H3.57763L8.46063 18.7216C8.67963 19.4246 9.94863 23.5966 10.2536 24.5576C10.4336 25.1316 10.6086 25.4906 10.8276 25.7646C10.9336 25.9046 11.0576 26.0216 11.2066 26.1156C11.2841 26.1606 11.3667 26.1962 11.4526 26.2216L11.4026 26.2096C11.4176 26.2136 11.4296 26.2256 11.4406 26.2296C11.4806 26.2406 11.5076 26.2446 11.5586 26.2526C12.3316 26.4866 12.9526 26.0066 12.9526 26.0066L12.9876 25.9786L15.8706 23.3536L20.7026 27.0606L20.8126 27.1076C21.8196 27.5496 22.8396 27.3036 23.3786 26.8696C23.9216 26.4326 24.1326 25.8736 24.1326 25.8736L24.1676 25.7836L27.9016 6.65458C28.0076 6.18258 28.0346 5.74058 27.9176 5.31158C27.797 4.8774 27.5184 4.50399 27.1366 4.26458C26.8161 4.06969 26.4443 3.97563 26.0696 3.99458ZM25.9686 6.04458C25.9646 6.10758 25.9766 6.10058 25.9486 6.22158V6.23258L22.2496 25.1626C22.2336 25.1896 22.2066 25.2486 22.1326 25.3076C22.0546 25.3696 21.9926 25.4086 21.6676 25.2796L15.7576 20.7486L12.1876 24.0026L12.9376 19.2126L22.5936 10.2126C22.9916 9.84258 22.8586 9.76458 22.8586 9.76458C22.8866 9.31058 22.2576 9.63158 22.2576 9.63158L10.0816 17.1746L10.0776 17.1546L4.24163 15.1896V15.1856L4.22663 15.1826C4.23686 15.1792 4.24688 15.1752 4.25663 15.1706L4.28863 15.1546L4.31963 15.1436C4.31963 15.1436 9.53063 12.9476 14.8276 10.7176C17.4796 9.60058 20.1516 8.47558 22.2066 7.60758C23.4254 7.09458 24.6454 6.58458 25.8666 6.07758C25.9486 6.04558 25.9096 6.04558 25.9686 6.04558V6.04458Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#354E57",
    },
  },
};
</script>